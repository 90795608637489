import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("views/home/home"),
  },
  {
    path: "/scheme",
    component: () => import("views/scheme/scheme"),
  },
  {
    path: "/knowledge",
    component: () => import("views/knowledge/knowledge"),
  },
  {
    path: "/maixiangyi",
    component: () => import("views/products/maixiangyi"),
  },
  {
    path: "/jingluoyi",
    component: () => import("views/products/jingluoyi"),
  },
  {
    path: "/appDownload",
    component: () => import("views/products/appDownload"),
  },
  {
    path: "/appInfo",
    component: () => import("views/products/appInfo"),
  },
  {
    path: "/about",
    component: () => import("views/about/about"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
