<template>
  <div class="foot">
    <div class="div-left">
      <div>
        <p>
          公司官网：深圳市大富智慧健康科技有限公司
          <a style="color: #bbbbbb" href="http://www.sktcm.com"
            >www.sktcm.com</a
          >
        </p>
        <!-- <p>联系我们：客服热线 0755-368 501 14</p> -->
        <p class="record">
          深圳市大富智慧健康科技有限公司版权所有©2015-2023
          <a style="color: #8d8d8d" href="https://beian.miit.gov.cn"
            >粤ICP备14053417号</a
          >
        </p>
      </div>
    </div>
    <div class="div-right">
      <img src="~assets/img/common/QRcode.png" alt="" />
      <div class="saoma">扫码关注官方公众号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot'
}
</script>

<style scoped>
.foot {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #3b3b3c;
  padding: 60px 160px;
  width: 100%;
  min-width: 1220px;
}

.div-left {
  color: #fff;
  font-size: 14px;
}

.div-left p {
  margin: 15px 0;
}

.div-left p.record {
  padding-top: 10px;
  border-top: 1px solid #aaa;
}

.saoma {
  color: #fff;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
}
</style>
