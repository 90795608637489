<template>
  <div class="top">
    <div class="lin">
      欢迎来到大富智慧健康加盟网
      <div class="btns">
        <div class="login">
          <a href="/user/login.php" style="color: #951e23">登录</a>
          <!-- <a href="http://www.sktcm.com/user/login.php"
             style="color: #951e23">登录</a> -->
        </div>
        <div class="apply">
          <a href="/weidian/Clinic/Ygregister/index.html" style="color: #fff"
            >申请试用</a
          >
          <!-- <a href="http://www.sktcm.com/weidian/Clinic/Ygregister/index.html"
             style="color: #fff">申请试用</a> -->
        </div>
        <div class="jiaocheng">
          <a
            href="http://ysk.sktcm.com/weidian/Clinic/Yghome/contactus.html"
            target="blank"
            style="color: #fff"
            >在线教程</a
          >
        </div>
      </div>
    </div>
    <div class="logo-bar">
      <div class="logo">
        <router-link to="/home">
          <img src="~assets/img/common/logo.png" alt="" />
        </router-link>
        <div class="li"></div>
        <div>愿景：让一亿人告别亚健康！</div>
      </div>
      <!-- <div class="telephone">
        <img src="~assets/img/common/tel.png" alt="" />
        <div class="li2"></div>
        <div>服务热线：0755-3685 0114</div>
      </div> -->
    </div>
    <!-- <main-tab-bar/> -->
    <el-menu
      :default-active="$route.path"
      mode="horizontal"
      router
      background-color="#951e23"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item index="/home">招商加盟</el-menu-item>

      <!-- <el-menu-item index="/scheme">医馆方案</el-menu-item> -->

      <el-menu-item index="/knowledge">知识体系</el-menu-item>

      <el-submenu index="/products">
        <template slot="title">产品介绍</template>
        <div>
          <el-menu-item index="/maixiangyi">智能血压脉象仪</el-menu-item>
          <el-menu-item index="/jingluoyi">便携式经络检测仪</el-menu-item>
          <el-menu-item index="/appDownload">软件下载</el-menu-item>
          <!-- <el-menu-item index="/appInfo">软件使用说明</el-menu-item> -->
        </div>
      </el-submenu>

      <el-menu-item index="/about">关于我们</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'Top'
}
</script>

<style scoped>
.top {
  width: 100%;
  min-width: 1220px;
}

.lin {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  color: #555555;
  background-color: #fafafa;
  padding: 0 106px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
}

.btns {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.btns div {
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  text-align: center;
  margin-right: 15px;
}
.btns div:last-child {
  margin-right: 0;
}
.login {
  border: 1px solid #951e23;
}

.apply {
  background-color: #951e23;
}

.jiaocheng {
  background-color: #3097c7;
}

.logo-bar {
  display: flex;
  height: 97px;
  align-items: center;
  justify-content: space-between;
  padding: 0 106px;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #4d4d4d;
}

.logo-bar img {
  object-fit: none;
}

.li {
  height: 97px;
  border-left: 1px solid #ebebeb;
  margin: 0 20px;
}

.li2 {
  height: 22px;
  border-left: 1px solid #ebebeb;
  margin: 0 10px;
}

.telephone {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #525151;
}
</style>

<style>
.el-menu {
  display: flex;
  justify-content: center;
  padding: 0 5% !important;
  border-bottom: 1px solid #ccc !important;
  box-shadow: 0 2px 4px #ccc;
}

.el-menu > .el-menu-item,
.el-submenu > .el-submenu__title {
  font-size: 20px !important;
  line-height: 71px !important;
  height: 71px !important;
}

.el-menu-item {
  font-size: 18px !important;
  line-height: 50px !important;
  height: 50px !important;
}

.el-menu-item,
.el-submenu {
  float: none !important;
  flex: 1;
  text-align: center;
}

.el-menu-item.is-active {
  background-color: #ff7008 !important;
}

.el-submenu.is-active .el-submenu__title {
  background-color: #ff7008 !important;
}
</style>
